import { useEffect, useMemo, useRef, useState } from 'react';
import useFetchSingleRequest from '../../hooks/vendor/useFetchSingleRequest';
import Request from '../../vender/requestModel/RequestModal';
import AssignTechnician from '../../vender/components/request/AssignTechnician';
import ActionAccess from '../../csr/bulkInfoModal/components/ActionAccess';
import { Button, TextField } from '@mui/material';
import { toast } from '../../../components/Toast/CustomToast';
import HandleLoad from '../../../components/Loader/HandleLoad';
import PreventiveAPI from '../../../api/preventiveApi';
import { initialView } from '../../vender/components/PaymentConfirmed';
import { UpdatedAtTime } from '../../csr/primaryComponents/AppointmentTime';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/reducers';
import AssignTechModel from '../../csr/components/schedule/components/AssignTechModel';
import AssignVendorModal from '../../csr/components/schedule/AssignVendorModal';
import ImageModal from '../../csr/primaryComponents/SubmitProofModel';
import Invoice from '../../vender/PrimaryComponents/invoice/Invoice';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import ViewChecklist from '../../vender/PrimaryComponents/ViewChecklist';
import BulkInfoModel from '../../csr/bulkInfoModal';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
export const viewInvoiceCheck = (pmRequest: any) =>
  ['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
    pmRequest?.invoice?.invoice_status
  ) || pmRequest?.vendor_invoice_dispute;

const OpenModel = ({ item, handleClose, refetch }: any) => {
  const [bulkInfoModal, setBulkInfoModal] = useState({ open: false, data: {} } as any);
  useEffect(() => {
    if (item?.bulk_id) {
      setBulkInfoModal({
        open: true,
        data: item,
      });
    }
  }, [item?.bulk_id]);
  if (!item?.bulk_id) {
    return <SingleModel item={item} handleClose={handleClose} refetch={refetch} />;
  } else
    return (
      <>
        {bulkInfoModal.open && (
          <BulkInfoModel
            handleClose={() => {
              setBulkInfoModal({ open: false, data: {} });
              handleClose?.();
            }}
            data={bulkInfoModal?.data}
            refetch={() => {
              refetch?.();
            }}
          />
        )}
      </>
    );
};
const SingleModel = ({ item, handleClose, refetch }: any) => {
  const handleLoad = HandleLoad();
  const dispatch = useDispatch();
  const { setShowModel } = bindActionCreators(actionCreators, dispatch);
  const { checkList } = useFetchCheckList();
  const { fetchRequestData: fetchRequestDetails }: any = useFetchSingleRequest();
  const { requestServices, user, showModel }: any = useSelector(
    (state: RootState) => state.userState
  );
  const [rejectField, setRejectField] = useState<any>(false);
  const [assignTechnicianModal, setAssignTechnicianModal] = useState({ open: false, data: {} });
  const [requestModel, setRequestModal] = useState<any>({ open: false, data: {} });
  const [technicianModel, setTechnicianModel] = useState({ open: false, data: {} });
  const [vendorModal, setVendorModal] = useState({ open: false, data: {} });
  const [viewInvoiceModal, setViewInvoiceModal] = useState({ open: false, data: {} });
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const [view, setView] = useState(initialView);
  const noteRef: any = useRef();

  const refetchRequestData = () => {
    if (requestModel.data?.id || requestModel.data?.preventive_request_id) {
      fetchRequestDetails({
        ...requestModel.data,
        id: requestModel.data?.id || requestModel.data?.preventive_request_id,
      }).then((res: any) => {
        if (res) {
          setRequestModal({
            open: true,
            data: { ...res, id: res?.id || item?.preventive_request_id },
          });
          if (showModel?.show) {
            setShowModel({
              show: true,
              data: { ...res, id: res?.id || item?.preventive_request_id },
            });
          } else {
            setRequestModal({
              open: false,
              data: {},
            });
            setShowModel({
              show: false,
              data: {},
            });
          }
          refetch?.();
        }
      });
    }
  };
  useEffect(() => {
    if (!item?.bulk_id) {
      if (!item?.all_users) {
        fetchRequestDetails({
          ...item,
          id: item?.id || item?.preventive_request_id || item?.request_id,
        }).then((res: any) => {
          if (res) {
            setRequestModal({
              open: true,
              data: { ...res, id: res?.id || item?.preventive_request_id },
            });
            if (showModel?.show) {
              setShowModel({
                show: true,
                data: { ...res, id: res?.id || item?.preventive_request_id },
              });
            }
          }
        });
      } else {
        setRequestModal({
          open: true,
          data: item,
        });
        if (showModel?.show) {
          setShowModel({
            show: true,
            data: item,
          });
        }
      }
    }
  }, [item?.id]);
  const handleRemoveTechnician = (item: any) => {
    handleLoad(true);
    PreventiveAPI.removeTechnician({ preventive_request_id: item?.preventive_request_id })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${item?.technician?.name} Removed`);
          refetchRequestData();
          refetch?.();
          if (isPartner) {
            setRequestModal({ open: false, data: {} });
          }
        } else {
          toast.error('Someting went wrong');
        }
      })
      .catch((err) => console.log(err));
  };
  const handleMoveState = (
    id: string,
    status: any | string,
    note?: string,
    isImageModel?: boolean
  ) => {
    handleLoad(true);
    let status_id, sub_stage;
    if (typeof status === 'string') {
      status_id = status;
    } else {
      status_id = status.status_id;
      sub_stage = status.sub_stage;
    }
    PreventiveAPI.statusUpdate({ preventive_request_id: id, status_id, sub_stage, note: note })
      .then((res) => {
        if (res.status === 200) {
          setRejectField(false);
          handleSetView({});
          if (!isImageModel) {
            setRequestModal({ open: false, data: {} });
          }
          refetchRequestData();
        } else {
          toast.error('someting went wrong');
        }
        handleLoad(false);
      })
      .catch((error) => console.log(error));
  };
  const handleSetView = (obj: any, data: any = {}) => {
    setView({
      ...initialView,
      ...obj,
    });
  };
  const assignedInfo = {
    fullbody2: () => {
      if (rejectField)
        return [
          (item: any) => (
            <ActionAccess item={item}>
              <div className='flex flex-col justify-between p-3  border-[0.3rem] border-primary-light rounded-xl divide-blue-300  rounded-lg gap-3'>
                <div className='flex justify-end'>
                  <button
                    className='border-none py-1 px-6  text-grey rounded-lg'
                    onClick={() => setRejectField(false)}>
                    Cancel
                  </button>
                </div>
                <TextField
                  multiline
                  inputRef={noteRef}
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '0.7rem',
                    },
                    width: '100%',
                    '& >div': {
                      borderRadius: '0.8rem',
                    },
                  }}
                  rows={5}
                  placeholder='Reason for Rejection'
                />
                <div className='w-full flex justify-center flex-col items-center gap-2'>
                  <button
                    className='py-3 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg w-full'
                    onClick={() => {
                      const reason = noteRef?.current?.value?.trim() || '';
                      if (reason?.length === 0) {
                        toast.info('Please enter reason of rejection ');
                        return;
                      }
                      handleMoveState(requestModel.data.id, '10', reason);
                    }}>
                    <i className='pi pi-times-circle text-sm p-1'></i>
                    Reject
                  </button>
                </div>
              </div>
            </ActionAccess>
          ),
        ];
    },
    body5: () => {
      switch (requestModel?.data?.status_id) {
        case '5':
          return [
            (item: any) => (
              <div className='w-full flex justify-end'>
                <Button
                  disabled
                  variant='contained'
                  sx={{ width: '60%', margin: 'auto', textTransform: 'capitalize' }}>
                  {'Technician Assigned'}
                </Button>
              </div>
            ),
          ];
        case '6':
          return [
            (item: any) => (
              <div className='w-full flex justify-end'>
                <Button
                  disabled
                  variant='contained'
                  sx={{ width: '60%', margin: 'auto', textTransform: 'capitalize' }}>
                  {'In Progress By Technician'}
                </Button>
              </div>
            ),
          ];
        case '7':
          return [
            (item: any) => (
              <div className='w-full flex justify-end'>
                <Button
                  variant='contained'
                  sx={{ width: '60%', margin: 'auto', textTransform: 'capitalize' }}
                  onClick={() => {
                    handleSetView(
                      {
                        submitProof: true,
                      },
                      item
                    );
                  }}>
                  {'Add Proof'}
                </Button>
              </div>
            ),
          ];
        // case '8':
        //   return [
        //     (item: any, checklistData: any[] = []) => {
        //       const AllProofs: any = getProofs(
        //         requestServices[item?.preventive_request_id || item?.id]
        //       );
        //       return (
        //         <ActionAccess item={item}>
        //           <>
        //             {!rejectField && (
        //               <div className='w-full flex justify-between'>
        //                 <button
        //                   className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg'
        //                   onClick={() => setRejectField(true)}>
        //                   <i className='pi pi-times-circle text-sm p-1'></i>
        //                   Reject
        //                 </button>
        //                 <Tooltip
        //                   title={AllProofs.length === 0 ? 'Please Upload Work Proof' : ''}
        //                   placement='bottom'>
        //                   <button
        //                     disabled={AllProofs.length === 0}
        //                     className='border-none py-1 px-6 bg-blue-500 text-white rounded-lg'
        //                     onClick={() =>
        //                       handleMoveState(requestModal.data.id, {
        //                         status_id: 9,
        //                         sub_stage: null,
        //                       })
        //                     }>
        //                     <i className='pi pi-check-circle text-sm p-1'></i>
        //                     Approve
        //                   </button>
        //                 </Tooltip>
        //               </div>
        //             )}
        //           </>
        //         </ActionAccess>
        //       );
        //     },
        //   ];
        case '8':
          return [
            (item: any) => (
              <div className='w-full flex justify-end'>
                <Button
                  variant='text'
                  sx={{
                    width: '60%',
                    margin: 'auto',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                  }}
                  className='bg-blue-200 text-blue-600'
                  onClick={() => {
                    handleSetView(
                      {
                        submitProof: true,
                      },
                      item
                    );
                  }}>
                  {requestServices[item?.preventive_request_id || item?.id]?.find(
                    (elem: any) => elem?.proofs?.length > 0
                  )
                    ? 'Re-Upload Proofs'
                    : 'Upload Proofs'}
                </Button>
              </div>
            ),
          ];
        default:
          return [];
      }
    },
    status: () => {
      switch (requestModel?.data?.status_id) {
        case '5':
          return [
            (item: any) => (
              <div
                className={`px-3 py-2 bg-amber-200 rounded-lg flex justify-between items-center`}>
                <p>Assigned To Technician</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '6':
          return [
            (item: any) => (
              <div className={`px-3 py-2 bg-sky-200 rounded-lg flex justify-between items-center`}>
                <p>Technician Started Working</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '7':
          return [
            (item: any) => (
              <div
                className={`px-3 py-2 bg-slate-200 rounded-lg flex justify-between items-center`}>
                <p>In To Do</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];
        case '8':
          return [
            (item: any) => (
              <div className={`px-3 py-2 bg-rose-200 rounded-lg flex justify-between items-center`}>
                <p>Invoicing</p>
                <p>
                  <UpdatedAtTime item={item} />
                </p>
              </div>
            ),
          ];

        default:
          return [];
      }
    },
  };
  const approvedInfo = useMemo(
    () => ({
      body5: () => {
        return [
          (item: any, number: number) =>
            viewInvoiceCheck(item) && (
              <div className='w-full flex justify-center'>
                <button
                  className='py-2 px-6 bg-blue-600 text-white rounded-lg border-none'
                  onClick={() => {
                    setViewInvoiceModal({
                      open: true,
                      data: { ...item, title: `Request-${item?.preventive_request_id}` },
                    });
                  }}>
                  View Invoice
                </button>
              </div>
            ),
        ];
      },
      body5paymentConfirmed: () => {
        if (['9'].includes(requestModel?.data?.status_id)) {
          return [];
        } else {
          return [
            (item: any, number: number) =>
              viewInvoiceCheck(item) && (
                <div className='w-full flex justify-center'>
                  <button
                    className='py-2 px-6 bg-blue-600 text-white rounded-lg border-none'
                    onClick={() => {
                      setViewInvoiceModal({
                        open: true,
                        data: { ...item, title: `Request-${item?.preventive_request_id}` },
                      });
                    }}>
                    View Invoice
                  </button>
                </div>
              ),
          ];
        }
      },
      body6: () => {
        return [(item: any) => <></>];
      },
      status: () => {
        switch (requestModel.data?.status_id) {
          case '9':
            return [
              (item: any) => (
                <div
                  className={`px-3 py-2 bg-slate-200 rounded-lg flex justify-between items-center`}>
                  <p>Proofs Accepted</p>
                  <p>
                    <UpdatedAtTime item={item} />
                  </p>
                </div>
              ),
            ];
          case '11':
            if (viewInvoiceCheck(requestModel?.data)) {
              return [
                (item: any) => (
                  <div
                    className={`px-3 py-2 bg-rose-200 rounded-lg flex justify-between items-center`}>
                    <p>Invoice Received</p>
                    <p>
                      <UpdatedAtTime item={item} />
                    </p>
                  </div>
                ),
              ];
            } else {
              return [
                (item: any) => (
                  <div
                    className={`px-3 py-2 bg-slate-200 rounded-lg flex justify-between items-center`}>
                    <p>Proofs Accepted</p>
                    <p>
                      <UpdatedAtTime item={item} />
                    </p>
                  </div>
                ),
              ];
            }
          case '12':
            return [
              (item: any) => (
                <div
                  className={`px-3 py-2 bg-green-200 rounded-lg flex justify-between items-center`}>
                  <p>Payment Completed</p>
                  <p>
                    <UpdatedAtTime item={item} />
                  </p>
                </div>
              ),
            ];
          default:
            return [];
        }
      },
    }),
    [checkList, requestModel]
  );
  const handleRequestModalData = useMemo(
    () => ({
      body5: [
        (item: any) =>
          item.status_id === '10' && (
            <div className='flex justify-between bg-red-200 items-center rounded-lg text-red-600 my-1'>
              <div className='flex gap-3 items-center py-1 px-3'>
                <i className='pi pi-times-circle' style={{ fontSize: '1rem' }}></i>
                <p>Rejected</p>
              </div>
              <Button
                variant='contained'
                color={'error'}
                className='px-4 font-bold cursor-pointer h-[2.5rem] w-[8rem] rounded-l-none'
                onClick={() => {
                  setAssignTechnicianModal({
                    open: true,
                    data: item,
                  });
                }}>
                Re-Assign
              </Button>
            </div>
          ),
      ],
      status: [
        (item: any) => (
          <div className={`px-3 py-2 bg-rose-200 rounded-lg flex justify-between items-center`}>
            <p>Rejected</p>
            <p>
              <UpdatedAtTime item={item} />
            </p>
          </div>
        ),
      ],
    }),
    []
  );
  const isRequestsVendor = (statusId: number, subStage: number) => statusId === 4 && subStage === 1;
  const isRequestsPartner = (statusId: number, subStage: number) =>
    statusId === -1 && subStage === 1;
  const isAcceptedVendor = (statusId: number, subStage: number) => statusId === 4 && subStage === 2;
  const isAcceptedPartner = (statusId: number, subStage: number) =>
    statusId === -1 && subStage === 2;
  const isAssignedVendor = (statusId: number) => [5, 6, 7, 8].includes(statusId);
  const isAssignedPartner = (statusId: number, subStage: number) =>
    [5, 6, 7, 8].includes(statusId) || (statusId === 4 && [1, 2].includes(subStage));
  const isApproved = (statusId: number, subStage: number) =>
    [9, 11, 12].includes(statusId) &&
    !([12].includes(statusId) && [1].includes(subStage)) &&
    ((statusId === 12 && !subStage) || [9, 11].includes(statusId));
  const isDisputed = (statusId: number, vendorInvoiceDispute: string) =>
    statusId === 11 && ['raised', 'declined'].includes(vendorInvoiceDispute);
  const isPaymentConfirmed = (statusId: number, subStage: number) =>
    statusId === 12 && subStage === 1;
  const isRejected = (statusId: number, subStage: number, isRejected: any, rejected: any) =>
    (statusId === 10 && (isRejected || rejected)) ||
    ([4, -1].includes(statusId) && [3].includes(subStage));

  const requestType = () => {
    const statusId = +requestModel?.data?.status_id;
    const subStage = +requestModel?.data?.sub_stage;
    const vendorInvoiceDispute = requestModel?.data?.vendor_invoice_dispute;
    const isRejectedFlag = requestModel?.data?.is_rejected;
    const rejectedFlag = requestModel?.data?.rejected;
    const is_requests: boolean =
      user?.role === 'fleetmax_vendor'
        ? isRequestsVendor(statusId, subStage)
        : isRequestsPartner(statusId, subStage);
    const is_accepted: boolean =
      user?.role === 'fleetmax_vendor'
        ? isAcceptedVendor(statusId, subStage)
        : isAcceptedPartner(statusId, subStage);
    const is_assigned: boolean =
      user?.role === 'fleetmax_vendor'
        ? isAssignedVendor(statusId)
        : isAssignedPartner(statusId, subStage);
    if (is_requests) {
      return 'requests';
    } else if (is_accepted) {
      return 'accepted';
    } else if (is_assigned) {
      return 'assigned';
    } else if (isApproved(statusId, subStage)) {
      return 'approved';
    } else if (isDisputed(statusId, vendorInvoiceDispute)) {
      return 'disputed';
    } else if (isPaymentConfirmed(statusId, subStage)) {
      return 'paymentConfirmed';
    } else if (isRejected(statusId, subStage, isRejectedFlag, rejectedFlag)) {
      return 'rejected';
    } else {
      return '';
    }
  };

  const type = requestType();
  const assignedData = useMemo(() => {
    return {
      ...requestModel.data,
      setTechnicianModel,
      setVendorModal,
      fetchRequestData: refetchRequestData,
      setAssignTechnicianModal,
      handleRemoveTechnician,
    };
  }, [requestModel?.data]);
  const updateStatusToAssignedTech = (id: string, status: string) => {
    handleLoad(true);
    PreventiveAPI.statusUpdate({
      preventive_request_id: id,
      status_id: status,
    }).then((res) => {
      if (res.status === 200) {
        refetchRequestData();
        refetch?.();
        toast.success('Re-assigned task');
      } else {
        toast.error('Something Went Wrong');
      }
      handleLoad(false);
    });
  };
  const rejectedData = useMemo(() => {
    if (type === 'rejected') {
      return {
        ...requestModel.data,
        setTechnicianModel,
        setVendorModal,
        fetchRequestData: refetchRequestData,
        setAssignTechnicianModal,
        handleRemoveTechnician,
        updateStatusToAssignedTech,
      };
    }
    return {};
  }, [type, requestModel?.data]);
  const handleCloseAll = () => {
    setRequestModal({ open: false, data: {} });
    handleClose();
    setShowModel({ show: false, data: {} });
  };
  return (
    <>
      {!item?.bulk_id && (
        <>
          {requestModel.open && type === 'requests' && (
            <Request
              handleClose={handleCloseAll}
              item={{
                ...requestModel.data,
                refetch: refetchRequestData,
                fetchRequestData: refetchRequestData,
              }}
              type='requests'
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'accepted' && (
            <Request
              handleClose={handleCloseAll}
              item={{
                ...requestModel.data,
                refetch: refetchRequestData,
                fetchRequestData: refetchRequestData,
                setAssignTechnicianModal,
              }}
              type='accepted'
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'assigned' && (
            <Request
              handleClose={handleCloseAll}
              item={assignedData}
              type={'assigned'}
              body5={assignedInfo.body5()}
              status={assignedInfo.status()}
              refetch={refetchRequestData}
              fullbody2={assignedInfo.fullbody2()}
            />
          )}
          {requestModel.open && type === 'approved' && (
            <Request
              handleClose={handleCloseAll}
              item={requestModel?.data}
              handleView={handleSetView}
              body5={approvedInfo.body5()}
              body6={approvedInfo.body6()}
              status={approvedInfo.status()}
              type={'approved'}
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'disputed' && (
            <Request
              handleClose={handleCloseAll}
              item={requestModel.data}
              handleView={handleSetView}
              body5={approvedInfo.body5()}
              status={approvedInfo.status()}
              type={'approved'}
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'paymentConfirmed' && (
            <Request
              handleClose={handleCloseAll}
              item={requestModel.data}
              handleView={handleSetView}
              body5={approvedInfo.body5paymentConfirmed()}
              status={approvedInfo.status()}
              type={'approved'}
              refetch={refetchRequestData}
            />
          )}
          {requestModel.open && type === 'rejected' && (
            <Request
              handleClose={handleCloseAll}
              item={rejectedData}
              type={'rejected'}
              body5={handleRequestModalData.body5}
              status={handleRequestModalData.status}
              handleView={handleSetView}
              refetch={refetchRequestData}
            />
          )}
          {assignTechnicianModal.open && (
            <AssignTechnician
              handleClose={() => setAssignTechnicianModal({ open: false, data: {} })}
              item={assignTechnicianModal.data}
              fetchRequestData={refetchRequestData}
            />
          )}
          {technicianModel?.open && (
            <AssignTechModel
              data={{ ...technicianModel?.data }}
              handleClose={() => {
                setTechnicianModel({ open: false, data: {} });
              }}
              refetch={() => {
                refetchRequestData();
              }}
            />
          )}
          {vendorModal.open && (
            <AssignVendorModal
              data={vendorModal.data}
              handleClose={() => {
                setVendorModal({ open: false, data: {} });
              }}
              refetch={() => {
                refetchRequestData();
              }}
            />
          )}
          {view.submitProof && (
            <ImageModal
              handleClose={() => {
                handleSetView({});
              }}
              item={requestModel.data}
              handleMoveStage={handleMoveState}
            />
          )}
          {view.viewChecklist && (
            <ViewChecklist checkedItems={checkList} handleView={handleSetView} />
          )}
          {viewInvoiceModal.open && (
            <Invoice
              handleClose={() => setViewInvoiceModal({ open: false, data: {} })}
              item={viewInvoiceModal.data}
              fetchRequestData={() => {
                refetchRequestData();
                setRequestModal({ open: false, data: {} });
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default OpenModel;
