import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ModalForm from '../../../../../components/ModalForm';
import { Button } from '@mui/material';
import OverlayContainer from '../../../../../components/OverlayContainer';
import { showWarning } from './AddCustom';

export default function AddCustomWeek({ handleClose, newSearchParams }: any) {
  const [_, setSearchParams] = useSearchParams();
  const [selectedWeeks, setSelectedWeeks] = React.useState<string[]>([]);
  const [selectedYear, setSelectedYear] = React.useState(dayjs().year());
  const [isNextYearDisabled, setIsNextYearDisabled] = React.useState(false);

  const currentDate = new Date();

  const weeksInYear = (year: number) => {
    const weeks = [];
    let currentDay = new Date(year, 0, 1);
    let weekNumber = 1;

    // Adjust the currentDay to the nearest Sunday
    while (currentDay.getDay() !== 0) {
      currentDay = new Date(currentDay.setDate(currentDay.getDate() - 1));
    }

    while (currentDay.getFullYear() <= year) {
      const startOfWeek = new Date(currentDay);
      const endOfWeek = new Date(currentDay);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      weeks.push({
        weekNumber,
        startOfWeek: new Date(startOfWeek),
        endOfWeek: new Date(endOfWeek),
      });

      weekNumber++;
      currentDay.setDate(currentDay.getDate() + 7);
    }

    return weeks;
  };

  const handleWeekToggle = (weekNumber: number) => {
    const weekLabel = `${selectedYear}-W${weekNumber}`;
    setSelectedWeeks((prev) =>
      prev.includes(weekLabel) ? prev.filter((w) => w !== weekLabel) : [...prev, weekLabel]
    );
  };

  const handleOkClick = () => {
    newSearchParams.delete('months');
    newSearchParams.delete('isMonthsCustom');
    newSearchParams.set('weeks', JSON.stringify(selectedWeeks));
    newSearchParams.set('isWeeksCustom', 'true');
    setSearchParams(newSearchParams);
    handleClose();
  };

  const handlePrevYear = () => {
    setSelectedYear((prev) => prev - 1);
  };

  const handleNextYear = () => {
    const nextYear = selectedYear + 1;
    const weeksNextYear = weeksInYear(nextYear);
    const hasSelectableWeeks = weeksNextYear.some(({ startOfWeek }) => startOfWeek <= currentDate);

    if (hasSelectableWeeks) {
      setSelectedYear(nextYear);
    }
  };

  React.useEffect(() => {
    const nextYear = selectedYear + 1;
    const weeksNextYear = weeksInYear(nextYear);
    const hasSelectableWeeks = weeksNextYear.some(({ startOfWeek }) => startOfWeek <= currentDate);
    setIsNextYearDisabled(!hasSelectableWeeks);
  }, [selectedYear]);

  const weeks = weeksInYear(selectedYear);

  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[55vw] '} noLabel>
        <div className='flex flex-col gap-4'>
          <div className='mb-4 flex justify-between gap-1 items-center'>
            <div className='justify-start gap-1 items-center'>
              <Button className='text-[2rem]' onClick={handlePrevYear}>
                <i className='pi pi-chevron-left'></i>
              </Button>
              <span className='mx-4 text-xl'>{selectedYear}</span>
              <Button
                className='text-[2rem]'
                onClick={handleNextYear}
                disabled={isNextYearDisabled}>
                <i className='pi pi-chevron-right'></i>
              </Button>
            </div>
            <div className='w-fit mr-8'>{showWarning(selectedWeeks, 'Weeks')}</div>
          </div>
          <div className='grid grid-cols-8 gap-4 bg-primary-light rounded-[0.5rem] p-1 py-2'>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Week'].map((day) => (
              <div key={day} className='font-bold text-center text-primary'>
                {day}
              </div>
            ))}
          </div>
          <div className='flex flex-col gap-2 max-h-[30rem] overflow-auto p-1'>
            {weeks.map(({ weekNumber, startOfWeek, endOfWeek }) => {
              const isDisabled = startOfWeek > currentDate;
              return (
                <div key={weekNumber} className='grid grid-cols-8 items-center'>
                  {Array.from({ length: 7 }, (_, i) => {
                    const day = new Date(startOfWeek);
                    day.setDate(day.getDate() + i);
                    const isSelected = selectedWeeks.includes(`${selectedYear}-W${weekNumber}`);
                    const isMiddleFiveDays = i >= 1 && i <= 5;
                    const monthShort = day.toLocaleString('default', { month: 'short' });
                    return (
                      <div
                        key={i}
                        className={`p-2 ${
                          isDisabled
                            ? 'bg-gray-300 text-gray-500'
                            : isSelected && isMiddleFiveDays
                            ? 'bg-primary-light'
                            : isSelected
                            ? `bg-blue-500 text-white ${i === 0 && 'rounded-l-full'} ${
                                i === 6 && 'rounded-r-full'
                              }`
                            : 'bg-white rounded-full border'
                        } text-center`}>
                        <div className='text-[1.1rem]'>{day.getDate()}</div>
                        <div className='text-xs'>{monthShort}</div>
                      </div>
                    );
                  })}
                  <div
                    className={`p-2 border rounded cursor-pointer rounded-[0.5rem] ${
                      isDisabled
                        ? 'bg-gray-300 text-gray-500'
                        : selectedWeeks.includes(`${selectedYear}-W${weekNumber}`)
                        ? 'bg-blue-500 text-white'
                        : 'bg-white hover:bg-primary-lighter'
                    } text-center ml-2`}
                    onClick={() => !isDisabled && handleWeekToggle(weekNumber)}>
                    W{weekNumber}
                  </div>
                </div>
              );
            })}
          </div>

          <div className='flex justify-end gap-3 mt-4'>
            <Button className='text-[#C55] px-4 py-2 rounded mr-2' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={selectedWeeks?.length < 2 || selectedWeeks?.length > 12}
              onClick={handleOkClick}>
              Save
            </Button>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}
