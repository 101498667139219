import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { toast } from '../../../../../components/Toast/CustomToast';
import { useSearchParams } from 'react-router-dom';
import PartnerAPI from '../../../../../api/partnerApi';
import { TableRow } from '../../types/types';
import { getMiles } from '../../../../csr/primaryComponents/TillMiles';

interface DataRow {
  date: string;
  invoice_id: string;
  request_id: string;
  station_code?: string;
  dsp_shortcode?: string;
  van_name?: string;
  van_type?: string;
  vin_number?: string;
  dsp_address?: string;
  csr?: string;
  partner?: string;
  vendor?: string;
  technician: string;
  amount: string;
  service_items?: string;
  status: string;
  dsp_name?: string;
  miles?: string;
  current_miles?: string;
  miles_till_inspection?: string;
  service_completion_date?: string;
}

type SetDownloadOpenFunction = (open: boolean) => void;

interface FilterModalProps {
  openDownload: boolean;
  setDownloadOpen: SetDownloadOpenFunction;
  downloadType: string;
  totalRevenue: any;
  revenueType: any;
}
const FilterModal: React.FC<FilterModalProps> = ({
  openDownload,
  setDownloadOpen,
  downloadType,
  totalRevenue,
  revenueType,
}) => {
  const date = new Date();
  let selectedData: any = [];
  const [urlParams, setSearchParams]: any = useSearchParams();
  const [_, setOpenConfirmModel] = useState(false);
  const isVendorRequestReport = window.location.href.includes('vendorRequestReport');
  const [allWithoutFilterData, setAllWithoutFilterData] = useState<any>([]);
  const [filterType, setFilterType] = useState<'withFilter' | 'withoutFilter'>('withFilter');
  const { multiSelectInvoiceItems, user }: any = useSelector((state: RootState) => state.userState);

  const fields: { name: string; key: keyof DataRow }[] = [
    { name: 'Date', key: 'date' },
    { name: 'Invoice Id', key: 'invoice_id' },
    { name: 'Request Id', key: 'request_id' },
    { name: 'Station code', key: 'station_code' },
    { name: 'DSP Shortcode', key: 'dsp_shortcode' },
    { name: 'DSP Name', key: 'dsp_name' },
    { name: 'Van Name', key: 'van_name' },
    { name: 'Van Type', key: 'van_type' },
    { name: 'VIN Number', key: 'vin_number' },
    { name: 'DSP Address', key: 'dsp_address' },
    { name: 'CSR', key: 'csr' },
    { name: 'Vendor', key: 'vendor' },
    { name: 'Partner', key: 'partner' },
    { name: 'Technician', key: 'technician' },
    { name: 'Amount', key: 'amount' },
    { name: 'Service Items', key: 'service_items' },
    { name: 'Status', key: 'status' },
    ...(window.location.href.includes('/vendorRequestReport')
      ? [
          { name: 'Partner', key: 'partner' as keyof DataRow },
          { name: 'Last Preventive Maintaince', key: 'miles' as keyof DataRow },
          { name: 'Current Miles', key: 'current_miles' as keyof DataRow },
          { name: 'Miles Till Inspection', key: 'miles_till_inspection' as keyof DataRow },
          { name: 'Service completion date', key: 'service_completion_date' as keyof DataRow },
        ]
      : [
          { name: 'Invoice Id', key: 'invoice_id' as keyof DataRow },
          { name: 'Amount', key: 'amount' as keyof DataRow },
        ]),
  ];

  const initialSelectedFields: { [key in keyof DataRow]: boolean } = fields.reduce((acc, field) => {
    acc[field.key] = true;
    return acc;
  }, {} as { [key in keyof DataRow]: boolean });
  const [selectedFields, setSelectedFields] = useState({
    ...initialSelectedFields,
    vendor: user.role === 'fleetmax_vendor' ? false : true,
  });
  const dispatch = useDispatch();
  const { setMultiInvoiceItems } = bindActionCreators(actionCreators, dispatch);
  const handleClose = () => {
    setDownloadOpen(false);
    setSelectedFields({
      ...initialSelectedFields,
      vendor: user.role === 'fleetmax_vendor' ? false : true,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFields({ ...selectedFields, [event.target.name]: event.target.checked });
  };

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  useEffect(() => {
    if (isVendorRequestReport) {
      PartnerAPI.getVendorRequestReport({
        params: 'date_type=all&',
      }).then((res: any) => {
        if (res.status === 200) {
          const resultData: any = res.data.data;
          const finalApiData: TableRow[] = resultData?.requests?.map((item: any) => {
            return {
              date: `${formatDate(item?.log_date || item?.service_completed_date)}`,
              invoice_id: `${item?.invoice?.id || 'N/A'}`,
              request_id: `${item?.preventive_request_id || 'N/A'}`,
              csr: `${item?.csr?.name || 'N/A'}`,
              vendor: `${item?.vendor?.name || 'N/A'}`,
              technician: `${item?.technician?.name || 'N/A'}`,
              service_items: `${(item?.invoice?.services || item?.services)?.[0]?.name || 'N/A'}`,
              amount: `${
                item?.invoice?.total_amount === 0 || item?.invoice?.total_amount === null
                  ? 'N/A'
                  : item?.invoice?.total_amount
              }`,
              sub_request_status: `${item?.sub_request_status || 'N/A'}`,
              status: `${item?.invoice_status || item?.request_status || 'N/A'}`,
              station_code: `${item?.dsp?.station_code || 'N/A'}`,
              dsp_shortcode: `${item?.dsp?.dsp_short_code || 'N/A'}`,
              van_name: `${item?.vehicle?.name || 'N/A'}`,
              van_type: `${item?.vehicle?.type || 'N/A'}`,
              vin_number: `${item?.vehicle?.vin || 'N/A'}`,
              dsp_address: `${item?.dsp?.address || 'N/A'}`,
              partner: `${item?.partner?.name || 'N/A'}`,
              current_miles: `${item?.curr_miles !== undefined ? item?.curr_miles : 'N/A'}`,
              miles_till_inspection: getMiles(item) || 'N/A',
              attempt: item?.attempt || null,
              service_completion_date: item?.service_completed_date
                ? `${formatDate(item?.service_completed_date)}`
                : 'N/A',
            };
          });
          setAllWithoutFilterData(finalApiData);
        } else {
          setAllWithoutFilterData([]);
          toast.error('Something Went wrong');
        }
      });
    } else {
      PartnerAPI.getAllInvoiceDetails({
        params: 'date_type=all&',
      }).then((res: any) => {
        if (res.status === 200) {
          const resultData: any = res.data.data;
          const finalApiData: TableRow[] = resultData?.requests?.map((item: any) => {
            return {
              date: `${formatDate(item?.log_date || item?.service_completed_date)}`,
              invoice_id: `${item?.invoice?.id || 'N/A'}`,
              request_id: `${item?.preventive_request_id || 'N/A'}`,
              csr: `${item?.csr?.name || 'N/A'}`,
              vendor: `${item?.vendor?.name || 'N/A'}`,
              technician: `${item?.technician?.name || 'N/A'}`,
              service_items: `${(item?.invoice?.services || item?.services)?.[0]?.name || 'N/A'}`,
              amount: `${
                item?.invoice?.total_amount === 0 || item?.invoice?.total_amount === null
                  ? 'N/A'
                  : item?.invoice?.total_amount
              }`,
              sub_request_status: `${item?.sub_request_status || 'N/A'}`,
              status: `${item?.invoice_status || item?.request_status || 'N/A'}`,
              station_code: `${item?.dsp?.station_code || 'N/A'}`,
              dsp_shortcode: `${item?.dsp?.dsp_short_code || 'N/A'}`,
              van_name: `${item?.vehicle?.name || 'N/A'}`,
              van_type: `${item?.vehicle?.type || 'N/A'}`,
              vin_number: `${item?.vehicle?.vin || 'N/A'}`,
              dsp_address: `${item?.dsp?.address || 'N/A'}`,
              partner: `${item?.partner?.name || 'N/A'}`,
              current_miles: `${item?.curr_miles !== undefined ? item?.curr_miles : 'N/A'}`,
              miles_till_inspection: getMiles(item) || 'N/A',
              attempt: item?.attempt || null,
              service_completion_date: item?.service_completed_date
                ? `${formatDate(item?.service_completed_date)}`
                : 'N/A',
            };
          });
          setAllWithoutFilterData(finalApiData);
        } else {
          toast.error('Something Went wrong');
        }
      });
    }
  }, [isVendorRequestReport]);
  const [totalAmount, setTotalAmount] = useState(0);
  const calculateTotalAmount = () => {
    let total = 0;
    multiSelectInvoiceItems.forEach((invoice: any) => {
      if (invoice.status === 'payment_received') {
        total += parseFloat(invoice.amount === 'N/A' ? '0' : invoice?.amount);
      }
    });
    setTotalAmount(total);
  };
  useEffect(() => {
    calculateTotalAmount();
  }, [totalAmount, multiSelectInvoiceItems]);

  const handleDownload = () => {
    if (filterType === 'withoutFilter') {
      selectedData = allWithoutFilterData.map((row: any) => {
        const filteredRow: { [key in keyof DataRow]: string } = {} as {
          [key in keyof DataRow]: string;
        };
        Object.keys(selectedFields).forEach((key) => {
          if (selectedFields[key as keyof DataRow]) {
            filteredRow[key as keyof DataRow] = (row[key as keyof DataRow] || '').toString();
          }
        });
        return filteredRow;
      });
    } else {
      selectedData = multiSelectInvoiceItems.map((row: any) => {
        const filteredRow: { [key in keyof DataRow]: string } = {} as {
          [key in keyof DataRow]: string;
        };
        Object.keys(selectedFields).forEach((key) => {
          if (selectedFields[key as keyof DataRow]) {
            filteredRow[key as keyof DataRow] = (row[key as keyof DataRow] || '').toString();
          }
        });
        return filteredRow;
      });
    }
    function capitalizeFirstLetter(sentence: any) {
      let words = sentence.split(' ');
      let capitalizedWords = words.map((word: any) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      let capitalizedSentence = capitalizedWords.join(' ');
      return capitalizedSentence;
    }

    if (downloadType === 'excel') {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Selected Data');
      const headerRow = worksheet.addRow(
        Object.keys(selectedData[0]).map((column) => {
          const field = fields.find((field) => field.key === column);
          return field ? capitalizeFirstLetter(field.name) : '';
        })
      );
      selectedData.forEach((row: any) => {
        const rowData = Object.values(row).map((word: any) =>
          capitalizeFirstLetter(word === 'payment_received' ? 'Payment Received' : word)
        );
        worksheet.addRow(rowData);
      });
      const lastColumnIndex = headerRow.actualCellCount - 1;
      const lastRowIndex = headerRow.number + selectedData.length;
      worksheet.getCell(lastRowIndex + 1, lastColumnIndex + 1).value = '';
      worksheet.getCell(lastRowIndex + 2, lastColumnIndex).value = `Total Revenue: $${
        filterType === 'withoutFilter'
          ? parseFloat(totalRevenue || 0).toFixed(2)
          : totalAmount === undefined
          ? '0.00'
          : totalAmount.toFixed(2)
      }`;
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `${user.name}_${date.toLocaleDateString()}.xlsx`);
      });
    }

    if (downloadType === 'pdf') {
      const doc = new jsPDF();
      doc.setFontSize(6);
      doc.text('Bulk Invoice', 14.5, 15);
      const columns = Object.keys(selectedData[0]);
      const rows = selectedData.map((row: any) =>
        Object.values(row).map((word: any) =>
          capitalizeFirstLetter(word === 'payment_received' ? 'Payment Received' : word)
        )
      );
      rows.push([
        `Total Revenue: $${
          filterType === 'withoutFilter'
            ? parseFloat(totalRevenue || 0).toFixed(2)
            : totalAmount === undefined
            ? '0.00'
            : totalAmount.toFixed(2)
        }`,
        '',
        '',
        '',
        '',
        '',
      ]);
      autoTable(doc, {
        head: [
          columns.map((column) => {
            const field = fields.find((field) => field.key === column);
            return field ? capitalizeFirstLetter(field.name) : '';
          }),
        ],
        body: rows,
        startY: 20,
        styles: {
          cellPadding: 1.5,
          fontSize: 4,
        },
      });
      doc.save(`${user.name}_${date.toLocaleDateString()}.pdf`);
    }
    setMultiInvoiceItems([]);
    setSelectedFields({
      ...initialSelectedFields,
      vendor: user.role === 'fleetmax_vendor' ? false : true,
    });
    urlParams.delete('date_type');
    setSearchParams(urlParams);
    setFilterType('withFilter');
  };

  return (
    <div>
      <Modal
        open={openDownload}
        onClose={() => {
          setDownloadOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={{ ...style, minWidth: '18.75rem', height: '40.75rem' }}>
          <div className='flex justify-between items-center'>
            <Typography variant='h6' component='h2' sx={{ marginBottom: '0.2rem' }}>
              Choose the fields you want to download
            </Typography>
            <div
              className='cursor-pointer'
              onClick={() => {
                setOpenConfirmModel(false);
                handleClose();
              }}>
              <img
                src='../../svg/close.svg'
                alt='download'
                className='inline-block h-[1rem] w-[1.2rem] '
              />
            </div>
          </div>
          <div className='h-[32rem] overflow-y-auto'>
            {fields.map((field) => (
              <div>
                {user?.role === 'fleetmax_vendor' && field?.name === 'Vendor' ? (
                  ''
                ) : (
                  <FormControlLabel
                    key={field.key.toString()}
                    control={
                      <Checkbox
                        checked={selectedFields[field.key]}
                        onChange={handleCheckboxChange}
                        name={field.key.toString()}
                      />
                    }
                    label={field.name}
                  />
                )}
              </div>
            ))}
          </div>
          <div className='flex justify-end'>
            <Button
              onClick={() => {
                if (multiSelectInvoiceItems.length > 0) {
                  handleDownload();
                  handleClose();
                } else {
                  toast.error('Please Select Atleast One Row');
                }
              }}
              variant='contained'
              sx={{ marginTop: '16px' }}>
              Download
            </Button>
          </div>
        </Box>
      </Modal>

      {/* <ConfirmModal
        openConfirmModel={openConfirmModel}
        setOpenConfirmModel={setOpenConfirmModel}
        handleDownload={handleDownload}
        downloadType={downloadType}
        filterType={filterType}
        setFilterType={setFilterType}
        setDownloadOpen={setDownloadOpen}
        revenueType={revenueType}
      /> */}
    </div>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '1.2rem',
  boxShadow: 24,
  p: 2,
};

export default FilterModal;
