import { useMemo, useState } from 'react';
import CrossButtonsCalender from '../../../../../assets/CrossButtonCalender';
import Element from '../../../../../assets/Element';
import ApprpvedFrom from '../../ApprpvedFrom';
import { toast } from '../../../../../components/Toast/CustomToast';

export const File = ({ file, removeAttachment }: { file: any; removeAttachment: () => void }) => {
  const name = !file?.name ? file?.substring(95)?.split('.') : ['', ''];

  return (
    <>
      <p className='truncate text-ellipsis max-w-[9rem] overflow-hidden'>
        {file?.name && (
          <a href={URL.createObjectURL(file)} target='_blank' rel='noreferrer'>
            <p className='truncate text-ellipsis'>{file?.name}</p>
          </a>
        )}
        {!file?.name && (
          <a href={file} target='_blank' rel='noreferrer'>
            <p className='truncate text-ellipsis'>{name[0] + '.' + name[1]}</p>
          </a>
        )}
      </p>
      <p
        className='flex items-center mt-[0.1rem]'
        onClick={(e) => {
          e.preventDefault();
          removeAttachment();
        }}>
        <CrossButtonsCalender width='1rem' height='1rem' />
      </p>
    </>
  );
};

const ExpandFiles = ({ attachment, attachMentDetail }: any) => {
  const [showOtherAttachments, setShowOtherAttachments] = useState(false);
  const { removeAttachment, addAttachment, attachmentOperation } = attachMentDetail;

  return (
    <div className='w-full  justify-between gap-2  border-none text-[#4378c8] bg-slate-50 rounded-lg cursor-pointer'>
      <div className='overflow-auto max-h-[6rem]'>
        <div className='overflow-auto max-h-[6rem] '>
          {attachment?.slice(0)?.filter((file: any) => file !== 'null')?.length > 0 && (
            <div className='flex justify-between relative p-2'>
              {!showOtherAttachments &&
                attachment?.slice(0, 1)?.map((file: any, index: number) => {
                  return (
                    <div
                      key={index + file}
                      className='flex items-center  gap-2 p-[0.2rem] px-4 pl-3 border-none text-[#515151] bg-slate-50 rounded-lg text-[0.8rem] font-bold  disabled-element'>
                      {!showOtherAttachments && (
                        <>
                          <File file={file} removeAttachment={() => removeAttachment(index)} />
                        </>
                      )}
                    </div>
                  );
                })}
              {showOtherAttachments && (
                <div className='flex flex-col gap-2'>
                  {attachment
                    ?.slice(0)
                    ?.filter((file: any) => file !== 'null')
                    ?.map((file: any, index: number) => {
                      return (
                        <div
                          key={index + file}
                          className='flex items-center  gap-2 p-[0.2rem] px-4 pl-3 border-none text-[#515151] bg-slate-50 rounded-lg text-[0.8rem] font-bold '>
                          <File file={file} removeAttachment={() => removeAttachment(index)} />
                        </div>
                      );
                    })}
                </div>
              )}

              {attachment?.length > 1 && (
                <button
                  className='text-blue-800   border-transparent text-[0.9rem] font-bold  pr-1 sticky top-2 right-0 h-fit flex justify-between items-center 
              hover:text-primary
              '>
                  {!showOtherAttachments ? (
                    <>
                      <div onClick={() => setShowOtherAttachments(!showOtherAttachments)}>
                        + {attachment?.length - 1} More
                      </div>
                      <i
                        className='pi pi-angle-down text-[1rem] ml-1'
                        onClick={() => setShowOtherAttachments(!showOtherAttachments)}></i>
                    </>
                  ) : (
                    <i
                      className='pi pi-angle-up text-[1rem] ml-1'
                      onClick={() => setShowOtherAttachments(!showOtherAttachments)}></i>
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`flex items-center p-3 px-3 font-bold ${
          attachment?.length !== 0 && 'border-t-[0.1rem] border-[#E4E4E4]'
        }`}>
        <div>
          <label className='flex justify-between cursor-pointer'>
            <i className='pi pi-plus text-[0.7rem] font-bold mr-1 flex items-center'></i>
            <input
              className='mt-[1rem]'
              onClick={(e: any) => (e.target.value = '')}
              hidden
              type='file'
              onChange={(e) => {
                const selectedFiles = e.target.files;
                if (selectedFiles) {
                  if (selectedFiles?.[0].size > 10240000) {
                    toast.error('Image size cannot be more than 10 MB');
                    return;
                  }
                  const filesArray = Array.from(selectedFiles);
                  for (const file of filesArray) {
                    addAttachment(file);
                  }
                }
              }}
              // multiple
            />
            <p className='truncate text-ellipsis'>{attachmentOperation}</p>
          </label>
        </div>
      </div>
    </div>
  );
};

function Edit({
  data,
  approvedFrom,
  invoiceNumber,
  amount,
  attachment,
  invoiceAttachment,
}: {
  data: any;
  approvedFrom: string;
  invoiceNumber: string;
  attachment: File[];
  invoiceAttachment: File[];
  amount: any;
}) {
  const {
    setApprovedFrom,
    setInvoiceNumber,
    setAmount,
    removeAttachment,
    removeInvoiceAttachment,
    setInvoiceAttachment,
    handleClick,
    setAttachment,
  } = data;

  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    const isValidInput = /^(\d{1,6}(\.\d{0,4})?)?$/.test(newValue);
    const isWithinLimit = parseFloat(newValue) <= 999999.9999;
    if (newValue === '' || (isValidInput && isWithinLimit)) {
      setAmount(newValue);
    }
  };

  const attachMentDetail = useMemo(() => {
    const attachmentData = data?.lease_plan?.attachment;
    const attachmentOperation =
      attachmentData?.slice(0)?.filter((file: any) => file !== 'null')?.length > 0
        ? 'Edit Email Attachment'
        : 'Add Email Attachment';

    return {
      removeAttachment,
      addAttachment: (newFiles: File[]) => {
        setAttachment([...attachment, newFiles]);
      },
      attachmentOperation,
    };
  }, [attachment, data]);

  const invoiceAttachMentDetail = useMemo(() => {
    const invoice_attachment = data?.lease_plan?.invoice_attachment;
    const invoiceAttachmentOperation =
      invoice_attachment?.slice(0)?.filter((file: any) => file !== 'null')?.length > 0
        ? 'Edit Invoice / Email Screenshot'
        : 'Add Invoice / Email Screenshot';
    return {
      removeAttachment: removeInvoiceAttachment,
      addAttachment: (newFiles: File[]) => {
        setInvoiceAttachment([...invoiceAttachment, newFiles]);
      },
      attachmentOperation: invoiceAttachmentOperation,
    };
  }, [invoiceAttachment, data]);

  return (
    <div className='p-2 flex flex-col items-center justify-between min-h-[32rem]'>
      <p className='text-lg'>Lease Plan Update</p>
      {/* body1 */}
      <div className='w-full child:py-2'>
        <div>
          <label className='text-md text-slate-500' htmlFor='text1'>
            Approved From
          </label>
        </div>
        <div className='flex gap-4'>
          <ApprpvedFrom
            text={<strong>LeasePlan</strong>}
            image={
              <div className='bg-[url("/public/images/LeasePlan.png")] h-[1rem] w-[2rem]  bg-no-repeat bg-cover' />
            }
            checked={approvedFrom === 'lease_plan'}
            onClick={() => {
              if (approvedFrom === 'lease_plan') {
                setApprovedFrom(null);
              } else {
                setApprovedFrom('lease_plan');
              }
            }}
          />
          <ApprpvedFrom
            svg={<Element />}
            checked={approvedFrom === 'element'}
            onClick={() => {
              if (approvedFrom === 'element') {
                setApprovedFrom(null);
              } else {
                setApprovedFrom('element');
              }
            }}
          />
        </div>
        <div>
          <label className='text-md text-slate-500' htmlFor='text1'>
            Invoice Number
          </label>
          <input
            autoFocus
            id='text1'
            value={invoiceNumber}
            onChange={(e: { target: { value: string } }) => setInvoiceNumber(e.target.value)}
            type='text'
            className='border-solid border-2 border-gray p-1.5 pt-2 rounded-md w-full focus:outline-none focus:border-stone-400 invalid:border-rose-300 transition ease-in duration-300'
          />
        </div>
        <div className='mb-[0.5rem]'>
          <label className='text-md text-slate-500'>Amount *</label>
          <input
            value={amount ? amount : ''}
            onChange={handleInputChange}
            type='text'
            className='border-solid border-2 border-gray p-1.5 pt-2 rounded-md w-full focus:outline-none focus:border-stone-400 invalid:border-rose-300 transition ease-in duration-300'
          />
        </div>
      </div>
      {/* body2 */}
      <div className='w-full flex flex-col gap-4'>
        <ExpandFiles attachment={attachment} attachMentDetail={attachMentDetail} />
        <ExpandFiles attachment={invoiceAttachment} attachMentDetail={invoiceAttachMentDetail} />
      </div>
      {/* body1 */}
      <div className='w-full text-center mt-[1rem]'>
        <button
          disabled={
            !amount ||
            amount <= 0 ||
            approvedFrom === null ||
            (invoiceAttachment?.length === 0 && attachment?.length === 0)
          }
          className='py-2 border-none text-white bg-[#4378C8] rounded-lg w-1/2 disabled:bg-slate-300 mt-2'
          onClick={handleClick}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default Edit;
