import { useRef } from 'react';
import CsrAPI from '../../../../api/csrApi';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import { DeclineBox } from '../../../vender/PrimaryComponents/requestComponents/DeclineBox';
import { toast } from '../../../../components/Toast/CustomToast';

function RejectBox({ item }: { item: any }) {
  const loader = HandleLoad();
  const { setRejecting } = item;
  const noteRef: any = useRef();
  const isPartnerUnassign: boolean = item?.status_id === '-1';
  const isTechUnassign: boolean =
    item?.status_id === '5' && (!item?.sub_stage || item?.sub_stage === null);
  const throughDSP = () => {
    return (
      item?.appointments && item?.appointments?.length > 0 && item?.appointments[0]?.updated_at
    );
  };

  const rejectRequest = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    loader(true);
    CsrAPI.removeVendor({
      preventive_request_id: item.id,
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
      vendor_account_id: item.vendor.account_id,
      ...(throughDSP() ? {} : { direct_vendor: true }),
    }).then((res) => {
      setRejecting(false);
      if (res.status === 200) {
        item.refetch();
        if (
          +item.status_id === 3 ||
          ((+item.status_id === 4 || +item.sub_stage === 3) && item.refetchPm)
        ) {
          item.refetchPm();
        }
        toast.success(`Vendor Removed successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };

  const rejectRequestBulk = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    loader(true);
    CsrAPI.removeVendorBulk({
      bulk_requests: item.bulkSchedules.map((d: any) => ({
        preventive_request_id: d.id,
      })),
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
      vendor_account_id: item.vendor.account_id,
      bulk_id: item.bulk_id,
      ...(throughDSP() ? {} : { direct_vendor: true }),
    }).then((res) => {
      setRejecting(false);
      if (res.status === 200) {
        item?.refetch?.(true);
        if (
          +item.status_id === 3 ||
          ((+item.status_id === 4 || +item.sub_stage === 3) && item.refetchPm)
        ) {
          item.refetchPm();
        }
        toast.success(`Vendor Removed successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  const rejectPartner = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }
    loader(true);
    CsrAPI.unassignPartner({
      ...(item?.bulk_id
        ? {
            bulk_requests: item?.bulkSchedules?.map((d: any) => ({
              preventive_request_id: +d?.id,
            })),
          }
        : { bulk_requests: [{ preventive_request_id: +item?.id }] }),
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
      partner_account_id: String(item?.partner_account_id),
      ...(item?.bulk_id && { bulk_id: String(item?.bulk_id) }),
    }).then((res) => {
      setRejecting(false);
      if (res.status === 200) {
        item?.refetch?.();
        item?.refetchPm?.();
        toast.success(`Partner Removed successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  const rejectTech = () => {
    if (!noteRef?.current?.value?.trim()) {
      toast.warn('Please add note');
      return;
    }

    const payload = {
      ...(item?.bulk_id
        ? {
            bulk_requests: item?.bulkSchedules?.map((d: any) => ({
              preventive_request_id: +d?.id,
            })),
          }
        : { bulk_requests: [{ preventive_request_id: +item?.id }] }),
      ...(noteRef?.current?.value?.trim() && { note: noteRef?.current?.value?.trim() }),
      vendor_account_id: String(item?.vendor?.account_id),
      technician_account_id: String(item?.technician?.account_id),
      ...(item?.bulk_id && { bulk_id: String(item?.bulk_id) }),
    };
    loader(true);
    CsrAPI.unassignTech(payload).then((res) => {
      setRejecting(false);
      if (res.status === 200) {
        item?.refetch?.(true);
        item?.refetchPm?.();
        toast.success(`Technician Removed successfully`);
      } else {
        toast.error('someting went wrong');
      }
      loader(false);
    });
  };
  const handleReject = () => {
    if (isPartnerUnassign) {
      rejectPartner();
    } else if (isTechUnassign) {
      rejectTech();
    } else {
      if (item?.bulk_id) {
        rejectRequestBulk();
      } else {
        rejectRequest();
      }
    }
  };
  const isTechUnassignNote: string = isTechUnassign ? 'technician' : 'vendor';
  return (
    <DeclineBox
      {...{ setRejecting, noteRef, handleReject }}
      noteInfo={`Since you’re unassigning the ${
        item?.status_id === '-1' ? 'partner' : isTechUnassignNote
      }, You need to Provide reason of the same`}
      btnInfo={'Submit & Unassign'}
    />
  );
}

export default RejectBox;
